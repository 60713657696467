/* eslint-disable no-unused-vars */
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Button from '../elements/button'
import CaseListItem from '../CaseListItem'
import env from '../../.env.mjs'
import { Link } from 'react-router-dom'
import { ArrowSmLeftIcon, ArrowSmRightIcon } from '@heroicons/react/solid'

export default function CaseList () {
  const [todoList, setTodoList] = useState([])
  const [queryParams, setQueryParams] = useState({ page: 1, limit: 10, sortOrder: 1, done: false, sortField: 'nextCourtDate', dateFrom: null, dateTo: null, type: 'all' })
  const [count, setCount] = useState(0)
  const [dateRange, setDateRange] = useState([])
  useEffect(() => {
    window.document.title = 'case list'
    const source = axios.CancelToken.source()
    axios.get(`
    ${env.API_URL}/tasks?
    page=${queryParams.page}
    &limit=${queryParams.limit}
    &sortOrder=${queryParams.sortOrder}
    &sortField=${queryParams.sortField}
    &done=${queryParams.done}
    &dateFrom=${queryParams.dateFrom}
    &dateTo=${queryParams.dateTo}
    &type=${queryParams.type}`.trim().replace(/\s+/g, ''), /* format URL Query */
    {
      withCredentials: true,
      cancelToken: source.token
    })
      .then(res => {
        setTodoList(res.data.docs)
        setDateRange(res.data.dateAggregation)
        setCount(res.data.count)
      })
      .catch(e => { console.info(e) })
    return () => {
      source.cancel('cancel request')
    }
  }, [queryParams])
  /* class functions */
  const toggleSortOrder = () => {
    setQueryParams({
      ...queryParams,
      sortOrder: queryParams.sortOrder < 0 ? 1 : -1,
      page: 1
    })
  }

  const changeFromDate = (e) => {
    setQueryParams({ ...queryParams, dateFrom: e.target.value, page: 1 })
  }
  const queryTypeHandler = (e) => {
    setQueryParams({ ...queryParams, type: e.target.value, page: 1 })
  }
  const changeToDate = (e) => {
    setQueryParams({ ...queryParams, dateTo: e.target.value, page: 1 })
  }
  const formatDate = date => {
    const dateobj = new Date(date)
    function pad (n) { return n < 10 ? '0' + n : n }
    return dateobj.getFullYear() + '-' + pad(dateobj.getMonth() + 1) + '-' + pad(dateobj.getDate())
  }
  const toggleCourtDatetoCreateDate = () => {
    setQueryParams({ ...queryParams, page: 1, sortField: queryParams.sortField === 'date' ? 'nextCourtDate' : 'date' })
  }

  const hideDoneHandler = () => {
    setQueryParams({ ...queryParams, done: !queryParams.done, page: 1 })
  }

  return (

    <>
      <div className='flex justify-around bg-blue-300 mt-6'>
        {/* the old buttons which have been replaced by text sorting */}
        {/* <Button title='Sort Case by Date Created' click={sortByCaseCreatedDateHandler} className={` py-0 hover:bg-gray-200 text-black bg-gray-100 ${queryParams.sortField === 'date' ? ' bg-orange-300' : ''} `}>
          {queryParams.sortField === 'date' && queryParams.sortOrder
            ? <ChevronDoubleDownIcon className='h-5' />
            : <ChevronDoubleUpIcon className='h-5' />}
        </Button>
        <Button title='Sort by next court date' click={sortByourtDateHandler} className={`py-0 hover:bg-gray-200 text-black bg-gray-100 ${queryParams.sortField === 'nextCourtDate' ? ' bg-orange-300' : ''} `} >
          {queryParams.sortField === 'nextCourtDate' && queryParams.sortOrder
            ? <SortDescendingIcon className=' inline h-5' />
            : <SortAscendingIcon className='inline h-5' />}
          <CalendarIcon className='inline h-5 ' />
        </Button>
        <Button title='toggle between OPEN and CLOSED' click={hideDoneHandler} className='py-0 hover:bg-gray-200 text-black bg-gray-100'>
          {queryParams.done
            ? <BanIcon className='h-5' />
            : <CheckIcon className='h-5' />}
        </Button> */}

        <div className='flex justify-between flex-grow '>
          <Button disabled={queryParams.page === 1}
            className={`${queryParams.page === 1 ? 'bg-gray-300 opacity-25' : 'bg-red-100 hover:bg-red-200'}`}
            click={() => {
              if (queryParams.page > 1) setQueryParams({ ...queryParams, page: queryParams.page - 1 })
            }}>
            <ArrowSmLeftIcon className='' />
            back
          </Button>
          <div className='text-center select-none grow'>

            <p className='text-lg  md:font-semibold'>page {queryParams.page} of {Math.ceil(count / queryParams.limit)}</p>
            <p className='text-sm'>{`${count} `}
              <span className={`p-[1px] m-[30=px] border-blue-200 bg-blue-200 rounded-sm shadow-xl border-[1px] cursor-pointer font-extrabold text-lg select-none  ${queryParams.done ? 'text-red-500' : 'text-green-600'}`}
                onClick={hideDoneHandler}>
                {queryParams.done ? 'closed' : 'open'}

              </span>
              <select className='m-2' onChange={queryTypeHandler} name="date-from" id="date-from" defaultValue={formatDate(Date())}>
                <option defaultValue='all'> all </option>
                <option value='felony'>Felony</option>
                <option value='misdemeanor'>Misd</option>
              </select>
              {' cases in '}
              <span className='p-[1px] m-[30=px] border-blue-200 bg-blue-200 rounded-sm shadow-xl border-[1px] cursor-pointer font-extrabold text-lg select-none' onClick={toggleSortOrder}>

                {`${queryParams.sortOrder > 0 ? ' ascending ' : ' descending'}`}
              </span>
              {' order by '}
              <span className='p-[1px] m-[30=px] border-blue-200 bg-blue-200 rounded-sm shadow-xl border-[1px] cursor-pointer font-extrabold text-lg select-none'
                onClick={toggleCourtDatetoCreateDate}>

                {` ${queryParams.sortField === 'date' ? 'Date created ' : 'court date '}`}
              </span>
            </p>
            <div className='flex justify-center'>
              {/* fliter case type */}
              {/* <div className='m-1'>{ }</div> */}
              <label htmlFor="date-from"> From:
                <select className='m-2' onChange={changeFromDate} name="date-from" id="date-from" defaultValue='--- from date ---'>
                  <option > -- from date -- </option>
                  {dateRange.map(date => (
                    <option key={date.date} value={date.date}>{formatDate(date.date)} ({date.count})</option>
                  ))}
                </select>
              </label>
              <label htmlFor="date-to">to:
                <select className='m-2' onChange={changeToDate} type="date" name="date-to" id="date-to" defaultValue='-- to date --'>
                  <option > -- to date -- </option>
                  {dateRange.map(date => (
                    <option key={date.date} value={date.date}>{formatDate(date.date)} ({date.count})</option>
                  ))}

                </select>
              </label>
            </div>
          </div>
          <Button
            className={count - (queryParams.page * queryParams.limit) > 0 ? 'bg-green-100 hover:bg-green-200' : 'bg-gray-300  opacity-25'} click={() => {
              if (count - (queryParams.page * queryParams.limit) > 0) {
                setQueryParams({ ...queryParams, page: queryParams.page + 1 })
              }
            }}>
            <ArrowSmRightIcon className='' />
            next

          </Button>

        </div>
      </div>
      <div>

        {todoList.length >= 1 && todoList.map((todo, index) => {
          return <CaseListItem key={todo._id} todo={todo} index={index} queryParams={queryParams} />
        })}
      </div>
      <div>

        {todoList.length < 1 &&
          <div className='text-center text-2xl h-[77vh]'>

            <p className='text-center  font-bold'>No items to display</p>
            <Link to='/createtodo' className='underline text-blue-500' >create a case!</Link>
          </div>
        }
      </div>

      <div className='flex justify-evenly bg-blue-300'>

        <Button disabled={queryParams.page === 1}
          className={`${queryParams.page === 1 ? 'bg-gray-300 opacity-25 ' : 'bg-red-100 hover:bg-red-200'}`}
          click={() => {
            if (queryParams.page > 1) setQueryParams({ ...queryParams, page: queryParams.page - 1 })
          }}>
          <ArrowSmLeftIcon className='h-9' />
        </Button>

        <p className='text-lg  md:font-semibold'>page {queryParams.page} of {Math.ceil(count / queryParams.limit)}</p>

        <Button
          className={count - (queryParams.page * queryParams.limit) > 0 ? 'bg-green-100 hover:bg-green-200' : 'bg-gray-300  opacity-25'}
          click={() => {
            if (count - (queryParams.page * queryParams.limit) > 0) {
              setQueryParams({ ...queryParams, page: queryParams.page + 1 })
            }
          }}>
          <ArrowSmRightIcon className='h-9' />

        </Button>
      </div>

    </>
  )
}
