import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Env from '../../.env.mjs'
import Witness from '../witness'

export default function Witnesses () {
  const [witnesses, setWitnesses] = useState([])
  useEffect(() => {
    window.document.title = 'witnesses to contact'
    axios.get(`${Env.API_URL}/tasks/witness`, { withCredentials: true })
      .then(res => setWitnesses(res.data))

    return () => {

    }
  }, [])
  return (

    witnesses?.map((wit, i) => {
      return (
      // maybe more will go here, like more explanation
      <>
          <Witness key={wit._id} wit={wit} short={true} />
      </>
      )
    })

  )
}
