import React from 'react'
import { MenuIcon, BellIcon, XIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import Button from './elements/button'
// import { appContext } from '../App'
export default function Menu ({ menu, toggleMenue, user, removeAlert, logOutFunc }) {
  // const {setTodoItem} = useContext(appContext)

  return (
    <div className=' select-none md:relative right-1  absolute  '>
      <div onClick={() => toggleMenue()} className="cursor-pointer">
        {menu
          ? <XIcon className=' active:bg-amber-300 bg-amber-200  h-12 md:h-12 ' />
          : <MenuIcon className=' active:bg-amber-300 bg-amber-200   h-12 md:h-12   ' />
        }

        <BellIcon className={user?.alerts?.length > 0
          ? 'absolute -bottom-1 h-4  text-rose-600'
          : 'hidden'} />
      </div>
      <div className={`bg-sky-200 px-2  m-2 overflow-y-auto overflow-x-hidden max-h-[90vh] absolute right-0  shadow-xl shadow-zinc-800 rounded-xl ${menu ? 'animate-pingIn' : 'hidden'}`}>

        {user?.alerts?.length > 0 &&
          <p className='text-center border-b-2 border-black bg-green-100 text-lg' >updates</p>
        }
        {user?.alerts?.map((item, index) => {
          return (
            <div className='text-center text-lg border-b-[1px] bg-green-200 border-green-700 hover:ring-1 hover:bg-green-300' onClick={(e) => removeAlert(e, item)} key={item.date + index}>
              <Link to={'/tasks/_id/' + item._id}>
                <p className='whitespace-nowrap   px-1 pt-1 rounded-md '>
                  {item.lastName} {item.firstName}
                </p>
                <p title='created date' className='text-center font-light text-sm'>{new Date(item.date).toLocaleString()}</p>
              </Link>
            </div>
          )
        }
        )}
        {/* history */}
        {user?.history?.length > 0 &&
          <p className='text-center whitespace-nowrap px-2 m-2 text-lg' >last 10 viewed cases</p>
        }
        {user?.history?.length > 0 && user?.history?.map((item, index) => (
          <div key={item.id} className='text-lg relative text-center border-b-[1px] border-black bg-zinc-50 rounded-md hover:bg-indigo-100 hover:ring-2 '>
            <Link key={item.id} to={'/tasks/_id/' + item.id} className='whitespace-nowrap' >
              <div className=' pt-[6px] pb-[2px] '>{item.fullName}</div>
              <p title='case type' className='text-center font-light text-xs absolute -top-1 -left-1 bg-slate-300 rounded-xl'>{item.type}</p>
              <p title='date and time when you last looked at this' className='text-center font-light text-sm'>{new Date(item?.date).toLocaleString()}</p>
            </Link>
          </div>

        )
        )}
        <div className='w-full'>

          <Button className='  bg-rose-400/90 hover:bg-rose-500 text-base' click={() => logOutFunc()}>logout {user?.fullName} </Button>
        </div>

      </div>

    </div>
  )
}
