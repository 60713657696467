import { PlusIcon } from '@heroicons/react/solid'
import React, { useContext, useState } from 'react'
import Env from '../.env.mjs'
import AddWitness from './addWitness'
// import { TodoItemCtx } from './Case/case'

import Witness from './witness'
import { appContext } from '../App.js'

export default function WitnessList () {
  const { todoItem, setTodoItem } = useContext(appContext)
  // const { setTodoItem, todoItem } = useContext(TodoItemCtx)
  const [addWitness, setAddWitness] = useState(false)
  const saveWitnessHandler = (witness) => {
    setAddWitness(!addWitness)
    if (witness.witnessFirstName.length === 0) {
      return console.info('Nothing to save')
    }
    fetch(`${Env.API_URL}/tasks/witness`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ witness })
    })
      .then((res) => res.json())
      .then((data) => {
        todoItem.witnesses.push(data)
        setTodoItem({ ...todoItem })
      })
      .catch((err) => {
        console.info('create witness errrrr createtodo.js')
        console.error('Err:', err)
      })
  }
  const toggleAddWitness = () => {
    setAddWitness(!addWitness)
  }
  const loadingWitnesses = () => {
    if (todoItem.witnesses === undefined) {
      return (
        'Loading..' + <span className='animate-pulse'>.</span>
      )
    }
  }
  return (
    <div className='pt-2'>

      <div className={addWitness ? 'hidden' : 'flex justify-center font-bold text-2xl'}>
        <p className="self-center font-mono text-center text-3xl ">

          {todoItem.witnesses === undefined ? loadingWitnesses() : todoItem.witnesses.length > 0 ? 'Witness List' : 'Please add a witness'}
        </p>
        <div title="add witness for this case" >
          <PlusIcon onClick={toggleAddWitness}
            className='h-10 ml-6 bg-stone-400 text-white hover:bg-yellow-200 rounded-xl shadow-lg' />
        </div>

      </div>
      {addWitness &&
        <AddWitness
          save={saveWitnessHandler}
          caseItem={todoItem}
          cancel={toggleAddWitness}
        />
      }

      {todoItem?.witnesses?.length > 0 &&
        todoItem?.witnesses?.map((witness, witnessIndex) => {
          return (
            <Witness
              key={witness._id}
              index={witnessIndex}
              witness={witness}
            // saveNote={saveNoteHandler}
            />
          )
        })}
    </div>
  )
}
