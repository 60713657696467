import { BrowserRouter as Router } from 'react-router-dom'
import NavBar from './components/navBar'
import LoginScreen from './components/LoginScreen'
import env from './.env.mjs'

import React, { createContext, useEffect, useState } from 'react'
import Switches from './components/Switches'
import axios from 'axios'
export const appContext = createContext()

function App () {
  const [loggedin, setLoggedin] = useState(false)
  const [checkingLogin, setCheckingLogin] = useState(false)
  const [user, setUser] = useState()
  const [loginmsg, setLoginmsg] = useState('Please Login')
  const [todoItem, setTodoItem] = useState({})
  const [editing, setEditing] = useState(false)

  const serializeForm = form => {
    const obj = {}
    const formData = new FormData(form.current)
    for (const key of formData.keys()) {
      obj[key] = formData.get(key)
      // console.log(key, formData.get(key))
    }
    return obj
  }
  useEffect(() => {
    setCheckingLogin(true)
    axios.get(`${env.API_URL}/users/auth/isLoggedin`, { withCredentials: true })
      .then(res => {
        if (res.data.status === 'signed in') {
          setLoggedin(true)
          setCheckingLogin(false)
          setUser({ ...res.data.user })
        }

        setCheckingLogin(false)
      })
      .catch(err => console.info(err))
  }, [])

  const logOutFunc = () => {
    axios.get(`${env.API_URL}/users/auth/logout`, { withCredentials: true })
      .then(() => setLoggedin(false))
  }
  const signin = (e, form) => {
    e.preventDefault()
    setCheckingLogin(true)
    axios.post(`${env.API_URL}/users/auth/signin`, { ...serializeForm(form), date: new Date().toLocaleString() }, { withCredentials: true })
      .then(res => {
        if (res.data.status === 'signed in' && res.data.user) {
          setLoggedin(true)
          setUser(res.data.user)
        }
        setCheckingLogin(false)
        setLoginmsg(res.data.message)
      })
      .catch(err => console.info(err))
  }

  if (!loggedin || checkingLogin) {
    return (
      <LoginScreen checkingLogin={checkingLogin} loginmsg={loginmsg} signin={signin}/>
    )
  } else {
    return (
      <div className="bg-zinc-300 min-h-screen text-sm md:text-base">
        <Router>
          <appContext.Provider value={{ user, setUser, logOutFunc, serializeForm, todoItem, setTodoItem, editing, setEditing }}>
            <NavBar user={user}/>
            <div className="pt-[2.8rem] md:pt-10 mx-auto lg:container">
              <Switches />
            </div>
          </appContext.Provider>
        </Router>
      </div>
    )
  };
}

export default App
