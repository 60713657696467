import React from 'react'
import SingleFile from './SingleFile'
export default function FilesList ({ todoItem }) {
  const files = todoItem.files

  if (files?.length > 0) {
    return (
      <div className=''>

        {files.map((file, index) => {
          return (
            <SingleFile
              key={file.path}
              index={index}
            />
          )
        })}
      </div>
    )
  } else {
    return (
      <div>
        <p className="italic text-center">(no files have been uploaded)</p>
      </div>
    )
  }
}
