import React, { useRef } from 'react'
// import { appContext } from '../App'
import Button from './elements/button'
import Input from './elements/input'
export default function LoginScreen ({ checkingLogin, loginmsg, signin }) {
  // const { signin, loginmsg, checkingLogin } = useContext(appContext)
  const form = useRef()

  return (
    <div className='bg-gray-300 flex  flex-col items-center justify-center h-screen '>

      {checkingLogin && <p className='animate-ping'>checking login session.....</p>}
      {!checkingLogin && <p>{loginmsg}</p>}
      <form ref={form} >

      <Input name='email' placeholder='email' type='email' autocomplete='username'/>
      <Input name='password' placeholder='password' type='password' autocomplete='current-password'/>
      <Button className='bg-teal-400 hover:bg-teal-500' click={(e) => signin(e, form)} >signin</Button>
      </form>
    </div>
  )
}
