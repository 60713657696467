import React, { useContext, useEffect, useRef, useState } from 'react'
// import { TodoItemCtx } from './Case/case'
import RTEComponent from './RTEComponent'
import Button from './elements/button'
import Input from './elements/input'
import { appContext } from '../App'
export default function AddWitness ({ save, cancel }) {
  const [content, setContent] = useState('')

  // const { todoItem, serializeForm } = useContext(TodoItemCtx)
  const { serializeForm, todoItem } = useContext(appContext)

  useEffect(() => {
    name.current.focus()
  }, [])
  const form = useRef()
  const name = useRef()

  const handleSave = () => {
    const data = serializeForm(form)
    data.witnessNotes = JSON.stringify(content)
    data.caseId = todoItem._id
    data.organization = todoItem.organization
    save(data)
  }

  return (
    <div className='col-span-2 m-4 '>
      <p>add a witness for {`${todoItem.firstName} ${todoItem.lastName || ''}`}</p>
      <form ref={form} action="" id='witness'>
        <Input myref={name} name='witnessFirstName' placeholder='First name'/>
        <Input name='witnessLastName' placeholder='Last name'/>
        <Input name='contact' placeholder='contact info'/>
        {/* <textarea className='w-full border-black border-2 col-span-2' placeholder="additional notes for this witness" name="witnessNotes" id="notes" spellCheck='true' rows='5'></textarea> */}
        <RTEComponent readonly={false} setContent={setContent}/>
      </form>
      <Button className='bg-green-200 hover:bg-green-300' click={handleSave}>Save Witness</Button>
      <Button className='bg-red-200 hover:bg-red-300' click={cancel}>cancel</Button>
    </div>
  )
}
