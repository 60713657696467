/* eslint-disable no-unused-vars */
import axios from 'axios'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Env from '../.env.mjs'
// import { TodoItemCtx } from './Case/case'
import RTEComponent from './RTEComponent'
import Button from './elements/button'
import Input from './elements/input'
import { appContext } from '../App.js'
export default function EditClient ({ save, cancel }) {
  const { todoItem, serializeForm } = useContext(appContext)

  const [users, setUsers] = useState([])
  const [content, setContent] = useState('')
  useEffect(() => {
    fName.current.focus()
    axios.get(`${Env.API_URL}/users`, { withCredentials: true })
      .then(res => {
        setUsers(res.data)
      })
      .catch(err => console.info(err))
  }, [])

  const form = useRef()
  const fName = useRef()
  const notesContent = useRef()

  const handleSave = () => {
    const data = serializeForm(form)
    const dateArr = data.nextCourtDate.split('-')
    data.nextCourtDate = new Date(
      `${dateArr[1]}-${dateArr[2]}-${dateArr[0]}`
    ).getTime()

    // data.notes = notesContent.current.texusetContent
    data.notes = JSON.stringify(content)
    // data.notes = returnContent()
    // console.log(data)
    save(todoItem._id, data)
  }

  const formatDate = (date) => {
    // console.info(date)
    const dateobj = new Date(date)
    function pad (n) {
      return n < 10 ? '0' + n : n
    }
    return (
      dateobj.getFullYear() +
      '-' +
      pad(dateobj.getMonth() + 1) +
      '-' +
      pad(dateobj.getDate())
    )
  }

  return (
    <div className="select-none  ">
      <p className="text-center mb-6 text-xl font-mono font-bold underline">
        edit details for {todoItem.firstName} {todoItem.lastName || ''}
      </p>
      <form className='p-5 grid grid-cols-12' ref={form} action="" id="witness">
        <div className='col-span-4'>
          <label className='flex flex-col' >
            First Name
            <Input
              myref={fName}
              name="firstName"
              placeholder="firstNames"
              defaultValue={`${todoItem.firstName} `}
            />
          </label>
        </div>
        <div className='col-span-4'>
          <label className='flex flex-col'>
            Last Name
            <Input
              name="lastName"
              placeholder="lastName"
              defaultValue={`${todoItem.lastName} `}
            />
          </label>
        </div>
        <div className='col-span-4'>
          <label className='flex flex-col'>
            Charge
            <Input
              name="charge"
              placeholder="charge"
              defaultValue={`${todoItem.charge}`}
            />
          </label>
        </div>
        <div className='col-span-3'>
          <label className='flex flex-col'>
            Case Number
            <Input
              name="caseNumber"
              placeholder="caseNumber"
              defaultValue={`${todoItem.caseNumber}`}
            />
            {/* <Input name='nextCourtDate' type='date' defaultValue={`${todoItem.nextCourtDate}`} /> */}
          </label>
        </div>
        <div className='col-span-2'>
          <label className='flex flex-col'>
            Next Court Date
            <Input
              name="nextCourtDate"
              className=""
              defaultValue={formatDate(todoItem.nextCourtDate)}
              type="date"
            />
          </label>
        </div>
        <div className='col-span-3 '>
          <label className='flex flex-col'>
            Attorney
            <select className='capitalize appearance-none m-2 p-2 rounded-lg border-gray-300 bg-gray-100 focus:bg-white focus:outline-none focus:ring-2 focus:border-transparent border-2' name="attorney">
                <option className='p-2 underline' value={todoItem.attorney || ''}>{todoItem.attorney}</option>
                {users.map(user => {
                  // console.info(user.fullName)
                  return (
                    <option key={user.fullName} value={user.fullName}>{user.fullName}</option>
                  )
                })}
              </select>
          </label>
        </div>
        <div className="col-span-3">
            <label className='text-center flex flex-col' >
              type
              <select className='capitalize appearance-none m-2 p-2 rounded-lg border-gray-300 bg-gray-100 focus:bg-white focus:outline-none focus:ring-2 focus:border-transparent border-2' name="type">
                <option hidden value={todoItem.type}>{todoItem.type}</option>
                <option value="misdemeanor">misdemeanor</option>
                <option value="felony">felony</option>
              </select>
            </label>
          </div>
        {/* <textarea className='w-full border-black border-2 col-span-2' defaultValue={todoItem.notes || 'add notes for this client'} name="notes" id="notes" ></textarea> */}
        <div
          className=" w-11/12  p-3 col-span-full"
          ref={notesContent}
        >

          {/* {todoItem.notes} */}
        <RTEComponent text={todoItem.notes} readonly={false} setContent={setContent}/>
        </div>
      </form>
      <Button className="bg-green-100 hover:bg-green-200" click={handleSave}>
        Save
      </Button>
      <Button className="bg-red-100 hover:bg-red-200" click={cancel}>
        cancel
      </Button>
    </div>
  )
}
