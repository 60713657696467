import React, { useEffect, useRef, useState } from 'react'
import SearchResults from './elements/searchResults'
import env from '../.env.mjs'
export default function SearchBar ({ serializeForm, user }) {
  const [searchQuery, setSearchQuery] = useState({ field: '', value: '' })
  const [searching, setSearching] = useState(false)
  const [searchbarFocus, setSearchbarFocus] = useState(false)
  const [searchResults, setSearchResults] = useState({})
  const [showResults, setShowResults] = useState(false)
  const searchForm = useRef()
  const searchBar = useRef()
  const searchSelect = useRef()
  // console.log(user)
  useEffect(() => {
    if (searchQuery.value === '' && searching) {
      console.log('1')
      /* reset search */
      setSearching(false)
      setSearchResults({})
      return
    }
    if (searchQuery.value !== '' && searchQuery.value.length >= 2) {
      console.log('2')
      const t = setTimeout(() => {
        console.log(user.role)
        fetch(`${env.API_URL}/search?field=${searchQuery.field}&value=${searchQuery.value}&org=${user.organization}&role=${user.role}`, { credentials: 'include' })
          .then(res => res.json())
          .then(res => {
            setSearching(false)

            // console.info('response complete', res)
            setSearchResults({ type: searchQuery.field, res })
            setShowResults(true)
          })
          .catch(e => console.warn(`fetch err: ${e}`))
      }, 700)
      return () => {
        clearTimeout(t)
      }
    }
  }, [searchQuery])

  const handleChange = () => {
    console.info('search update (change)')
    setSearchResults(null)
    setSearchQuery(serializeForm(searchForm))
    if (searchQuery.value.length >= 2) {
      setSearching(true)
    }
  }
  const searchBlur = () => {
    setSearchbarFocus(false)
    setTimeout(() => {
      setShowResults(false)
    }, 250)
  }
  const searchFocusHandler = () => {
    setSearchbarFocus(true)

    const query = serializeForm(searchForm)

    if (searchQuery.value && query.value === searchQuery.value && searchQuery.value !== '') {
      console.info(searchQuery.value, '===', query.value)
      setShowResults(true)
    } else if (searchQuery.value && searchbarFocus) {
      setSearchQuery({ ...searchQuery })
      setSearching(true)
      setShowResults(true)
    }
  }

  return (
    <form onSubmit={(e) => e.preventDefault()} ref={searchForm} id='searchBar' onChange={handleChange} className='flex-grow relative flex px-3'>
      <input ref={searchBar} onBlur={searchBlur} onFocus={searchFocusHandler} name='value' className=' border-2 border-black text-center w-full' placeholder='search' />
      <select ref={searchSelect} onFocus={searchFocusHandler} name='field' className=' border-2 border-black text-center text-base' placeholder='value' >
        <option value="all">All Text</option>
        <option value="client">client</option>
        <option value="witness">witness</option>
        <option value="phone">phone</option>
        <option value="caseNumber">case number</option>
        <option value="attorney">attorney</option>
        {/* <option value="charge">charge</option> */}
      </select>
      <SearchResults searchQuery={searchQuery} searchbarFocus={searchbarFocus} showResults={showResults} searching={searching} searchResults={searchResults} />
    </form>
  )
}
