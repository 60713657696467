import React from 'react'
import { NavLink } from 'react-router-dom'

export default function MyNavLink ({ children, to, exact }) {
  return (
    <NavLink
      className={
        ({ isActive }) =>
          'hover:bg-blue-100  whitespace-nowrap  p-2 m-2 select-none ' +
          (isActive ? 'border-b-4 border-black bg-blue-200 select-none ' : '')
      }
      exact={exact} to={to} >
      {children}
    </NavLink>
  )
}
