import React, { useState } from 'react'
import Button from './elements/button'
import env from '../.env.mjs'
import RTEComponent from './RTEComponent'
// import { appContext } from '../App'

import { PencilAltIcon } from '@heroicons/react/outline'
export default function Note ({ index, note }) {
  // const { user } = useContext(appContext)
  // console.log(user)
  const [content, setContent] = useState(note.action)
  const [thisNote, setThisNote] = useState(note.action)
  const [edit, setedit] = useState(false)

  const saveNote = () => {
    const newText = JSON.stringify(content)
    fetch(env.API_URL + '/tasks/notes/edit', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: note._id, note: newText })
    })
      .then(res => res.json())
      .then(data => {
        console.info(data)
        if (data.msg === 'saved!') {
          setedit(!edit)
          console.log(newText)
          setThisNote(newText)
        }
      })
      .catch(err => {
        console.info('errrrr createtodo.js')
        console.error('Err:', err)
      })
  }
  return (
    <>
      <div className='p-3 shadow-sm w-full rounded-lg mb-2'>
        {/* <div className='bg-blue-500 h-1 relative w-9 -left-5 top-6'></div> */}
        {!edit &&

          <div className='text-sm md:text-base '>
            <div className='flex justify-between'>
              <p className=' font-mono font-bold text-center'>Notes made on: {new Date(note.date).toLocaleString()}</p>
              <PencilAltIcon className=' h-7 top-0 right-0 cursor-pointer bg-slate-200 border-2 border-blue-500 rounded-xl' onClick={() => { setedit(!edit) }} />

            </div>
            <RTEComponent readonly={true} text={thisNote} setContent={setContent} />
            {/* <Button click={() => { setedit(!edit) }} className='bg-yellow-100 hover:bg-yellow-200 text-sm'>edit note</Button> */}
          </div>

        }

        {edit &&
          <>
            <RTEComponent readonly={false} text={thisNote} setContent={setContent} />
            <p className='text-xs'>(edit the text above and hit save)</p>
            <Button click={() => saveNote()} className='bg-green-300 hover:bg-green-400'>Save</Button>
            <Button click={() => { setedit(!edit) }} className='bg-red-300 hover:bg-red-400'>cancel</Button>
          </>
        }
      </div>
    </>
  )
}
