/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Button from './elements/button'
import env from '../.env.mjs'
import { debounce } from 'throttle-debounce'

export default function CaseListItem ({ todo, index, queryParams }) {
  const [thisTodo, setThisTodo] = useState(todo)
  const [date, setDate] = useState(false)
  const [updating, setUpdating] = useState(false)
  const markAsDone = (id, index, e) => {
    const change = { done: e.target.checked }
    fetch(`${env.API_URL}/tasks/edit`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id, change })
    })
      .then(res => res.json())
      .then(data => {
        console.info(data)
        thisTodo.done = e.target.checked
        setThisTodo({ ...thisTodo })
      })
      .catch(err => {
        console.info('errrrr checkdone caseListitem.js')
        console.error('Err:', err)
      })
  }

  const dateBlur = (e) => {
    if (date) {
      console.info(e.target.value)
    }
  }
  const dateFocus = () => {
    setDate(true)
  }

  const debounced = debounce(1300, (e) => {
    console.info('deboooo')
    console.info(e.target.value)
    const dateArr = e.target.value.split('-')
    const myDate = new Date(`${dateArr[1]}-${dateArr[2]}-${dateArr[0]}`).getTime()
    // console.log(myDate)
    if (myDate) {
      setUpdating(true)
      const id = thisTodo._id
      const change = { nextCourtDate: myDate }
      fetch(`${env.API_URL}/tasks/edit`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id, change })
      })
        .then(res => res.json())
        .then(data => {
          console.log(data)
          setTimeout(() => {
            setUpdating(false)
          }, 800)
        })
        .catch(err => {
          console.info('errrrr datechange todolistitem.js')
          console.error('Err:', err)
        })
    }
  })
  const dateChange = e => {
    debounced(e)
  }
  const done = thisTodo.witnesses?.filter(wit => {
    return wit.done
  })
  const formatDate = date => {
    const dateobj = new Date(date)
    function pad (n) { return n < 10 ? '0' + n : n }
    return dateobj.getFullYear() + '-' + pad(dateobj.getMonth() + 1) + '-' + pad(dateobj.getDate())
  }
  return (

    <div key={thisTodo._id} className={`${thisTodo.done ? 'line-through' : ''} flex bg-indigo-50 rounded-tr-3xl rounded-bl-3xl overflow-hidden m-3.5  p-1.5 ${thisTodo.type === 'felony' ? 'border-green-500 border-2' : ''}`}>

      <div className="bg-gray-200 flex-1 rounded-bl-3xl">
        <Link to={`/tasks/_id/${thisTodo._id}`} >
          <Button className='bg-yellow-100 w-10/12 hover:bg-yellow-200 ' color='yellow'>
            <p>{thisTodo.firstName} {thisTodo.lastName}</p>
          </Button>
        </Link>
        <p className='px-3'><span className='text-lg'>#</span>{` ${thisTodo.caseNumber}`}</p>
        <p className='p-2 underline'><span className='text-lg'>Charge:</span> {thisTodo.charge}</p>
      </div>
      <div className="bg-gray-100 flex-1">
        {/* <p className='p-3'>Case created on: {new Date(todo.date).toLocaleDateString()}</p> */}
        {queryParams &&
        <p className={`px-3 py-1 w-max border-b-2 ${queryParams.sortField === 'date' && queryParams.sortOrder ? ' border-red-500' : queryParams.sortField === 'date' && !queryParams.sortOrder ? 'border-green-500' : ''}`}>Date created: {new Date(todo.date).toLocaleDateString()}</p>
        }
        <p className='px-3'>{done?.length} / {thisTodo.witnesses?.length} witnesses contacted</p>
        <p className='px-3'><span className='text-lg'>Attorney:</span>{` ${thisTodo.attorney}`}</p>
        {thisTodo.type === 'felony' &&
          <p className='px-3'><span className='text-lg'>hours:</span>{` ${thisTodo.timeBilled}/ ${thisTodo.timeSpent}`}</p>
        }
      </div>
      <div className={updating ? 'border-rose-500 border-4' : ' border-2 border-blue-600 rounded-tr-3xl'}>
        <p className='h-1/5 font-bold text-center'>next court date</p>
        {/* alternative date input.  seems to word wither way */}
        <input className='h-2/5 w-full' onFocus={dateFocus} onBlur={dateBlur} onChange={dateChange} defaultValue={formatDate(thisTodo.nextCourtDate)} type="date" />
        {/* <input className='h-2/5 w-full' onFocus={dateFocus} onBlur={dateBlur} onChange={dateChange} defaultValue={new Date(todo.nextCourtDate).toISOString().slice(0, 10)} type="date" /> */}
        <div className='h-2/5 bg-white border-t-2 flex place-content-evenly place-items-center '>
          <label className='m-2.5 cursor-pointer select-none'>mark as done
          <input className='mx-2' onChange={(e) => markAsDone(thisTodo._id, index, e)} type="checkbox" id="done" /* disabled={todo.done} */ defaultChecked={thisTodo.done} />
          </label>
        </div>

      </div>
    </div>
  )
}
