/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { Editor, EditorState, RichUtils, convertToRaw, convertFromRaw, ContentState } from 'draft-js'
import 'draft-js/dist/Draft.css'

export default function RTEComponent ({ text, readonly, setContent = () => { } }) {
  // if (!text) {
  //   text = 'loading....'
  // }
  // if readonly is false then prevent window navigation
  // if (!readonly) {
  //   window.onbeforeunload = function () {
  //     return ''
  //   }
  // }

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  // if (!readonly) {
  //   console.log(text)
  // }
  useEffect(() => {
    let contentState
    // console.log('text', text)
    if (typeof text === 'string') {
      try {
        // console.log('one')
        contentState = EditorState.createWithContent(convertFromRaw(JSON.parse(text)))
        // console.log('try', text)
      } catch (error) {
        // console.log('two')
        // console.log(error)
        contentState = EditorState.createWithContent(ContentState.createFromText(text))
        // console.log('catch', text)
      }
    } else {
      contentState = EditorState.createEmpty()
      // contentState = EditorState.createWithContent(convertFromRaw(text))
      // console.log('else', text)
      // contentState = EditorState.createWithContent(convertFromRaw(JSON.parse(text)))
    }
    setEditorState(contentState)
  }, [text])
  // debugger
  const editor = useRef()
  // const focus = () => editor.current.focus()

  useEffect(() => {
    // this changes content state when I type into !readonly editor
    if (editorState !== undefined && !readonly) {
      // debugger
      // change**
      // setContent(convertFromRaw(editorState.getCurrentContent()))
      setContent(convertToRaw(editorState.getCurrentContent()))
    }
  }, [editorState])

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      setEditorState(newState)
      return true
    }
    return false
  }

  return (
    <div className={`${readonly ? 'bg-white py-4 px-7' : 'bg-amber-50 border-2 border-slate-500  focus-within:border-blue-300 '} px-3 py-1 text-base md:text-xl `}>
      {editorState &&
        <Editor
          placeholder={readonly ? 'no notes added.....' : '***Write notes here***'}
          spellCheck={true}
          ref={editor}
          // onFocus={focus}
          editorState={editorState}
          onChange={setEditorState}
          readOnly={readonly}
          handleKeyCommand={handleKeyCommand}
        />
      }
    </div>

  )
}
