/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState, useContext, createContext } from 'react'
import EditClient from '../editClient'
// import Button from '../elements/button'
import env from '../../.env.mjs'
import WitnessList from '../WitnessList'

import { appContext } from '../../App'
import { Navigate, useParams, useNavigate } from 'react-router-dom'
import RTEComponent from '../RTEComponent'
import FileArea from '../FileArea'
import { CheckIcon, DotsCircleHorizontalIcon, XIcon } from '@heroicons/react/solid'
import { PencilIcon, TrashIcon } from '@heroicons/react/outline'
import { debounce } from 'throttle-debounce'
export const TodoItemCtx = createContext({})

export default function Case () {
  const nav = useNavigate()

  const { user, serializeForm, setUser, todoItem, setTodoItem } = useContext(appContext)
  const fileInputRef = useRef(null)
  const hoursSpentRef = useRef(null)
  const hoursBilledRef = useRef(null)
  // const [todoItem, setTodoItem] = useState({})
  const [markingAsDone, setMarkingAsDone] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [editClient, setEditClient] = useState(false)
  const [dropFiles, setDropFiles] = useState([])
  // const [timeSpent, settimeSpent] = useState(todoItem.timeSpent)
  // const [timeBilled, settimeBilled] = useState(todoItem.timeBilled)

  const { field, value } = useParams()
  useEffect(() => {
    // console.log('fetch')
    fetch(`${env.API_URL}/tasks/${field}/${value}`, { credentials: 'include' })
      .then(res => {
        if (res.status !== 200) setNotFound(true)
        return res.json()
      })
      .then((json) => {
        setTodoItem({ ...json.caseQuery })
        setUser(json.userQuery)
        window.document.title = `${json.caseQuery.firstName} ${json.caseQuery.lastName}`
      })
      .catch(err => {
        console.error(err)
      })
  }, [field, value])

  const markAsDone = () => {
    const change = { done: !todoItem.done }
    const id = todoItem._id
    // console.log({ change, id })
    setMarkingAsDone(true)
    fetch(`${env.API_URL}/tasks/edit`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id, change })
    })
      .then(res => res.json())
      .then(data => {
        // console.info(data)
        setMarkingAsDone(false)
        todoItem.done = data.done
        setTodoItem({ ...todoItem })
      })
      .catch(err => {
        console.info('errrrr checkdone todolist.js')
        console.error('Err:', err)
      })
  }

  const toggleEditClient = () => {
    setEditClient(!editClient)
  }

  const saveClientUpdateHandler = (id, change) => {
    console.info({ id, change })
    fetch(`${env.API_URL}/tasks/edit`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id, change })
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data)
        setTodoItem({ ...todoItem, ...data })
        setEditClient(!editClient)
      })
  }
  const onFileInputClick = () => {
    fileInputRef.current.click()
  }

  const drop = (e) => {
    const files = e.target ? e.target.files : e
    Array.from(files).forEach((file, i) => {
      dropFiles.push(file)
    })
    console.info(e || e.target.files)
    setDropFiles([...dropFiles])
  }
  if (notFound) {
    return (
      <Navigate to={'/'} />
    )
  }
  const deleteCase = () => {
    const confirimDelete = prompt('type the CASE NUMBER to permanently delete this case')
    // console.log(confirimDelete)
    if (confirimDelete === todoItem.caseNumber) {
      // deleteCase()
      fetch(`${env.API_URL}/tasks/delete/${todoItem._id}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((res) => res.json())
        .then((data) => {
          nav('/')
        })
    }
  }

  useEffect(() => {
    // console.log('timeSpent', todoItem.timeSpent)
    // console.log('timeBilled', todoItem.timeBilled)
    const t = setTimeout(() => {
      // console.log('CHANGE!')
      setMarkingAsDone(true)
      const change = {}
      change.timeBilled = todoItem.timeBilled
      change.timeSpent = todoItem.timeSpent
      const id = todoItem._id
      fetch(`${env.API_URL}/tasks/edit`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id, change })
      })
        .then(res => {
          res.json()
          setMarkingAsDone(false)
        })
        .catch(err => {
          console.info('errrrr checkdone todolist.js')
          console.error('Err:', err)
        })
    }, 700)

    return () => {
      clearTimeout(t)
    }
  }, [todoItem.timeSpent, todoItem.timeBilled])

  const incrementFelonyHours = (e, value) => {
    setTodoItem({ ...todoItem, [value]: e.target.value })
    // console.log(e.target.value)
    // return updateHours(e, value)
    // debounce(700, updateHours(e, value))
  }
  return (
    <TodoItemCtx.Provider value={{ todoItem, user, setTodoItem, serializeForm }}>
      {/* for the fileDrop */}
      <input ref={fileInputRef} name="file" multiple onChange={drop} type="file" className="hidden" />
      {editClient &&
        <EditClient
          save={saveClientUpdateHandler}
          cancel={toggleEditClient}
        ></EditClient>
      }
      {!editClient &&
        <div className='m-4 bg-blue-500 p-1 md:text-xl'>
          {/* top bar */}
          <p className='font-mono font-bold text-sm text-zinc-300 '>Created: {new Date(todoItem.date).toLocaleString()}</p>
          {todoItem.type === 'felony' &&
            <div className='flex justify-around'>
              <label className=''>hours spent: {todoItem.timeSpent}
                <input ref={hoursSpentRef} className='w-14 ml-4' onChange={(e) => incrementFelonyHours(e, 'timeSpent')} type="number" value={0 || todoItem.timeSpent} step=".1" />

              </label>
              <label className=''>hours billed {todoItem.timeSpent}
                <input ref={hoursBilledRef} className='w-14 ml-4' onInput={(e) => incrementFelonyHours(e, 'timeBilled')} type="number" value={0 || todoItem.timeBilled} step=".1" />
              </label>
            </div>
          }
          <div className=" bg-blue-400 flex ">
            <div className='capitalize text-center grow'>
              <p className='md:text-3xl text-xl'>
                {todoItem.firstName} {todoItem.lastName}
              </p>
              <p className='text-lg'>
                {todoItem.caseNumber}
              </p>

            </div>
            <div className='border-l-2  border-slate-400 flex  select-none divide-x-2 divide-slate-400'>
              <div onClick={markAsDone} title='toggle case done' className='cursor-pointer flex flex-col px-2 hover:bg-slate-200'>
                {markingAsDone &&
                  <DotsCircleHorizontalIcon className='h-7 cursor-wait' />
                }
                {!todoItem.done && !markingAsDone &&
                  <CheckIcon className=' h-7 text-green-700 cursor-pointer' />
                }
                {todoItem.done && !markingAsDone &&
                  <XIcon className='h-7 text-red-500 cursor-pointer' />
                }
                <p className='text-base underline '>{markingAsDone ? 'loading' : todoItem.done ? 'done' : 'mark done'}</p>
              </div>
              <div onClick={deleteCase} title='delete case permanently' className='cursor-pointer flex flex-col px-2 hover:bg-slate-200'>
                <TrashIcon
                  className='h-7 text-black ' />
                <p className='text-base underline'>delete</p>
              </div>
              <div onClick={toggleEditClient} title='Edit Case' className='cursor-pointer px-2 hover:bg-slate-200 flex flex-col'>

                <PencilIcon className=' h-7 text-yellow-200 cursor-pointer ' />
                <p className='text-base underline'>edit</p>
              </div>

            </div>
          </div>

          <div className="flex bg-slate-200 justify-around text-center">
            <div className=' px-5 p-2'>
              <p className="font-mono  underline  ">
                Attorney
              </p>
              <p className=" font-bold capitalize">
                {todoItem.attorney}
              </p>
            </div>
            <div className=' px-5 p-2'>
              <p className=" font-mono underline  ">
                Charge
              </p>
              <p className=" font-bold ">
                {todoItem.charge}
              </p>
            </div>
            <div className=' px-5 p-2'>
              <p className="font-mono underline   ">
                next court date
              </p>
              <p className=" font-bold">
                {new Date(todoItem.nextCourtDate).toLocaleDateString()}</p>
            </div>
          </div>
          <p className='font-mono p-2 underline font-bold bg-white'>case notes:</p>

          <RTEComponent text={todoItem.notes} readonly={true} />
        </div>
      }
      <FileArea todoItem={todoItem} dropFiles={dropFiles} fileInputRef={fileInputRef} drop={drop} onFileInputClick={onFileInputClick} />

      <WitnessList />
      </TodoItemCtx.Provider >

  )
}
