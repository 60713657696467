/* eslint-disable no-unused-vars */
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import Env from '../../.env.mjs'
// import { TodoItemCtx } from '../Case/case'
import { appContext } from '../../App.js'

export default function FileUpload ({ file, fileInputRef }) {
  const { todoItem, setTodoItem } = useContext(appContext)
  // console.info(todoItem)
  const [fileState, setfileState] = useState({
    name: file.name,
    progress: 0,
    uploading: false
  })
  useEffect(() => {
    const data = new FormData()
    data.append('file', file)
    data.append('caseID', todoItem._id)
    console.info('upload!')
    axios.post(`${Env.API_URL}/tasks/upload`, data, {
      headers: {
        'content-type': 'multipart/form-data'
      },
      withCredentials: true,
      onUploadProgress: (event) => {
        // console.info(`${file.name} ${(event.loaded / event.total) * 100}%`)
        fileState.progress = Math.floor((event.loaded / event.total) * 100)
        fileState.uploading = true
        setfileState({ fileState, ...fileState })
      }
    })
      .then((res) => {
        console.info('uploaded!')
        todoItem.files.push(res.data.file)
        setTodoItem({ ...todoItem })
        // console.info(res.data.file)
        fileInputRef.current.value = ''
      })
      .catch((err) => console.error(err))
    return () => {}
  }, [])

  return (
    <div className={` ${fileState.progress === 100
      ? 'hidden'
      : 'font-mono relative flex  ml-2 mb-2 overflow-hidden'}`}>
      <p className='font-mono  bg-gray-300 '

      >
        {`${fileState.name}.....`}
        <span className="bg-white">
          {`${fileState.progress} %`}
        </span>

        </p>
        <div
          style={{ width: `${fileState.progress}%` }}
          className="bg-gray-500 h-0.5 absolute transition-all" >
        </div>

    </div>
  )
}
