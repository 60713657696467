import React from 'react'
import FileUpload from './elements/fileUpload'
import { FileDrop } from 'react-file-drop'
import FilesList from '../components/elements/FileList'

export default function FileArea ({ todoItem, dropFiles, fileInputRef, drop, onFileInputClick }) {
  return (
    <div className=" shadow-gray-500/50 shadow-lg px-4 rounded-3xl mx-4 my-7 bg-gray-200">
    {/* <p className="text-2xl text-center underline">File List</p> */}
    {dropFiles.length > 0 &&
      dropFiles.map((file, i) => {
        return (
          <FileUpload
            key={i}
            file={file}
            fileInputRef={fileInputRef}
          />
        )
      })}
          {/* {dropFiles.length === 0 &&

       <div>
        <p className="italic text-center">FILES AREA!!!</p>
      </div>
} */}
    <FileDrop
      className=""
      targetClassName="bg-white cursor-pointer border-2 border-orange-300  rounded-3xl"
      draggingOverFrameClassName="bg-blue-200"
      draggingOverTargetClassName=" border-orange-600"
      onDrop={drop}
      onTargetClick={onFileInputClick}
    >
      <p className="text-center text-2xl p-4 opacity-40">
        Click or drop files here to upload
      </p>
    </FileDrop>
    <FilesList todoItem={todoItem} />
  </div>

  )
}
