
import React, { useContext, useState } from 'react'
import SearchBar from './searchBar'
import { appContext } from '../App'
import Env from '../.env.mjs'
import axios from 'axios'
import MyNavLink from './elements/myNavLink'
import Menu from './menu'

export default function NavBar () {
  const { logOutFunc, user, setUser, serializeForm } = useContext(appContext)
  const [menu, setMenu] = useState(false)

  const toggleMenue = () => {
    setMenu(!menu)
  }
  const removeAlert = (e, item) => {
    e.preventDefault()
    // console.log(item, user)
    axios.post(`${Env.API_URL}/users/removealert`, { item, user }, { withCredentials: true })
      .then(res => {
        setUser(res.data)
      })
      .catch(err => console.info(err))
  }
  return (

    <div className='bg-white z-50  items-center text-xl  md:text-2xl w-full  shadow-lg flex fixed flex-col md:flex-row  ' >
      <div>
      {
          user?.role !== 'attorney' &&
          <MyNavLink to='/needtobill' >
            $
          </MyNavLink>
        }
        <MyNavLink to='/'>
          Cases
        </MyNavLink>
        {
          user?.role !== 'attorney' &&
          <MyNavLink to='/witnesses' >
            Witnesses
          </MyNavLink>
        }

        <MyNavLink to='/createtodo' >
          Create Case

        </MyNavLink>
      </div>
      <SearchBar serializeForm={serializeForm} user={user} />
      <Menu menu={menu} toggleMenue={toggleMenue} user={user} removeAlert={removeAlert} logOutFunc={logOutFunc} />

    </div>

  )
}
