/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import axios from 'axios'
import React, { useContext, useEffect, useRef, useState } from 'react'
import RFTIcons from 'react-file-type-icons'
import Env from '../../.env.mjs'
// import { TodoItemCtx } from '../Case/case'
import { appContext } from '../../App.js'
export default function SingleFile ({ index }) {
  // debugger
  const { setTodoItem, todoItem } = useContext(appContext)
  const [deletingFile, setDeletingFile] = useState(false)
  const [editFile, seteditFile] = useState(false)
  const [thisfile, setthisfile] = useState(todoItem.files[index])
  const editInput = useRef()
  useEffect(() => {
    if (editFile) {
      editInput.current.focus()
    }
  }, [editFile])
  const openEditHandler = () => {
    seteditFile(!editFile)
  }
  const deleteHandler = (file, index) => {
    const check = confirm('Are you sure you want to delete this file?')
    if (deletingFile) {
      console.info('already deleting!!!')
      return
    }
    if (check) {
      setDeletingFile(true)
      axios.get(`${Env.API_URL}/file/delete/${file._id}`, { withCredentials: true })
        .then(() => {
          todoItem.files.splice(index, 1)
          setDeletingFile(false)
          setTodoItem({ ...todoItem })
        })
    }
  }
  const confirmRenameHandler = (file, newName) => {
    axios.post(`${Env.API_URL}/file/edit`, { file, newName }, { withCredentials: true }
    )
      .then((res) => {
        console.info(res.data)
        setthisfile(res.data)
      })
      .then(() => seteditFile(!editFile))
  }
  function bytesToSize (bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return '0 Byte'
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
  }

  return (
    <div className="py-1 mx-2 px-2 flex items-center   ">
      {!editFile && (
        <>
        <span>{index + 1}.</span>
          <span
            title='rename file'
            onClick={openEditHandler}
            className="select-none cursor-pointer bg-gray-600 hover:bg-gray-400 shadow-md rounded-lg ml-2 p-1"
          >🖊️
          </span>

          <span
          title='delete file'
            onClick={() => deleteHandler(thisfile, index)}
            className="select-none cursor-pointer bg-gray-600 hover:bg-gray-400 shadow-md rounded-lg mx-2 p-1"
          >❌
          </span>
          <a
            className="bg-gray-300 m-2 p-2 shadow-md border-b-2 border-blue-300"
            href={`${Env.API_URL}/file/${thisfile._id}`}
            target="_blank"
            rel="noreferrer"
          >
            {thisfile.name}
          </a>
          <span className="text-xs text-gray-500">
            <RFTIcons colorType='multiColor' size='3em' className='inline-block' fileName={thisfile.name} key={Math.random()} />
            {`${bytesToSize(thisfile.size)}`}
          </span>
          <span className="text-right text-xs underline grow">
            {new Date(thisfile.date).toLocaleDateString()}
          </span>
        </>
      )}
      {editFile && (
        <>
          <input
            className="px-1.5 h-full py-0.5"
            ref={editInput}
            defaultValue={thisfile.name}
          />
          <span
            onClick={() => confirmRenameHandler(thisfile, editInput.current.value)}
            className="select-none bg-gray-600 shadow-md rounded-lg mx-2 px-2 py-1"
          >
            ☑️
          </span>
        </>
      )}
    </div>
  )
}
