/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import axios from 'axios'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Env from '../.env.mjs'
import { TodoItemCtx } from './Case/case'
import RTEComponent from './RTEComponent'
import Button from './elements/button'
import Input from './elements/input'
import Note from './note'
import { PencilIcon } from '@heroicons/react/outline'
import { appContext } from '../App.js'
// import { writeStorage, deleteFromStorage, useLocalStorage } from '@rehooks/local-storage'

export default function Witness ({ index, short, wit }) {
  const { todoItem, setTodoItem, serializeForm } = useContext(TodoItemCtx)

  // const { todoItem, setTodoItem, serializeForm } = useContext(appContext)
  const witness = wit || todoItem?.witnesses[index]
  // const [unSavedNote] = useLocalStorage(witness._id, localStorage.getItem(witness._id))
  const [content, setContent] = useState('')
  const [addNoteState, setaddNoteState] = useState(false)
  const [editWitness, seteditWitness] = useState(false)
  const witnessFormEdit = useRef()
  const cancelAddNote = () => {
    // deleteFromStorage(witness._id)
    setaddNoteState(false)
  }
  // console.log(localStorage.getItem(witness._id)) // returns string
  // console.log(unSavedNote)
  // console.log(Boolean(unSavedNote))

  // debugger

  // useEffect(() => {
  //   if (addNoteState) {
  //     console.log(JSON.stringify(content))
  //     /* change */
  //     writeStorage(witness._id, { content, index, witness })
  //   }
  //   // console.log(JSON.stringify(unSavedNote?.content))

  //   // writeStorage(witness._id, { content, index, witness })
  //   console.log('unSavedNote effect', content)
  //   return () => {

  //   }
  // }, [content])

  const saveNoteHander = (witId, data, index) => {
    setaddNoteState(false)
    if (!data) {
      console.info('no note to save')
      return
    }
    data = JSON.stringify(data)
    console.log(data)
    const date = new Date().getTime()
    const caseId = todoItem._id
    const organization = todoItem.organization
    // console.log(todoItem.organization)
    fetch(`${Env.API_URL}/tasks/notes`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ witId, data, caseId, organization, date })
    })
      .then((res) => res.json())
      .then((data) => {
        todoItem.witnesses[index] = data
        // deleteFromStorage(witness._id)

        setTodoItem({ ...todoItem })
      })
      .catch((err) => {
        console.info('errrrr createtodo.js')
        console.error('Err:', err)
      })
  }

  const done = witness?.done || todoItem?.witnesses[index].done
  const addNotesHandler = () => {
    setaddNoteState(true)
  }

  const updateWitness = (id, update, editStateModal) => {
    axios.post(`${Env.API_URL}/tasks/witness/edit`, { id, update, todoItem }, { withCredentials: true })
      .then(res => {
        console.info(res.data)
        todoItem.witnesses[index] = res.data
        console.log({ todoItem })
        setTodoItem({ ...todoItem })
        if (editStateModal) seteditWitness(!editWitness)
        // return setTodoItem(res.data.msg)
      })
  }
  const serialiseWitness = () => {
    const data = serializeForm(witnessFormEdit)
    data.witnessNotes = JSON.stringify(content)
    // console.log(data)
    return data
  }
  return (
    <div className='mt-3 last-of-type:mb-9 px-3 bg-blue-300 '>
      {editWitness &&
        <div className='fixed z-10 bg-black/80 right-0 top-0 h-screen w-screen'>

          <div className='bg-slate-100 rounded-xl border-2 top-1/3 relative mx-7 border-gray-500 px-10 pt-5 '>
            <p className='text-center  text-3xl'> Edit {witness.witnessFirstName || ''} {witness.witnessLastName || ''} details:</p>
            <form action="" id='witness' ref={witnessFormEdit} className=''>
              <div className='flex justify-around flex-col md:flex-row'>

                <Input name='witnessFirstName' placeholder='First name' defaultValue={witness.witnessFirstName} />
                <Input name='witnessLastName' placeholder='Last name' defaultValue={witness.witnessLastName} />
                <Input name='contact' placeholder='contact info' defaultValue={witness.contact} />
              </div>
              <RTEComponent text={witness.witnessNotes} readonly={false} setContent={setContent} />
              {/* <div className='bg-amber-100'>
              </div> */}
            </form>
            <div className='flex justify-around'>

              <Button click={() => updateWitness(witness._id, serialiseWitness(), true)} className='bg-green-100' >Save Witness</Button>
              <Button click={() => seteditWitness(!editWitness)} className='bg-red-100' >cancel</Button>
            </div>
          </div>
        </div>
      }
      {!editWitness &&
        <div className={`${!short ? ' bg-blue-300 pb-1 ' : ' bg-blue-300 p-3 border-2 border-black '}`}>
          <div className='flex text-xl md:text-3xl justify-between px-3 bg-blue-300 rounded-xl mb-1 border-black '>
              {witness.witnessFirstName} {witness.witnessLastName}

            <p className=''>Contact: {witness.contact}</p>
            {!short &&
              <div className='bg-slate-200 cursor-pointer  hover:bg-slate-300 rounded-full shadow-3xl h-full p-1' onClick={() => { seteditWitness(!editWitness) }} title='edit witness'>
                <PencilIcon className=' h-6 ' />
              </div>
            }
          </div>
            <p className='font-mono font-bold text-xs '>investigation started: {new Date(witness.date).toLocaleString()}</p>

          <div className="bg-white ">
            <p className='font-mono  p-2 underline font-bold'>Initial Witness Notes:</p>
            <RTEComponent text={witness.witnessNotes} readonly={true} setContent={setContent} />

          </div>

          {short &&
            <>
              <p className='capitalize'><span className='font-mono font-bold'>Attorney:</span>{witness.caseId.attorney}</p>
              <Link to={`/tasks/_id/${witness.caseId._id}`}>
                <Button className='bg-yellow-100 hover:bg-yellow-300 capitalize mb-5'>
                  {` Case:  ${witness.caseId.firstName} ${witness.caseId.lastName} #${witness.caseId.caseNumber} `}
                </Button>
              </Link>
              <p className='font-mono font-bold text-xs'>investigation started: {new Date(witness.date).toLocaleString()}</p>
              <div className=' p-1 '>
                <h1 className='p-2 bg-gray-200 font-bold underline '>CASE NOTES</h1>
                <RTEComponent text={witness.caseId.notes} readonly={true} />
              </div>
            </>}
        </div>
      }
      {/* {console.log({ witness })} */}
      {!short &&
        <div>
          {/* add note to witnesss */}
          {witness?.actions.length > 0 && witness.actions.map((note, index) =>
            <div key={index} className=' text-lg bg-blue-100  p-2 '>
              <Note index={index} note={note} />
            </div>
          )}
          {!addNoteState &&
            <div>
              <Button className='bg-indigo-100 hover:bg-indigo-200' click={() => addNotesHandler() }>add witness notes</Button>
              <Button className='bg-indigo-100 hover:bg-indigo-200' click={() => updateWitness(witness._id, { done: !witness.done }, null)} > {done ? 'done' : 'mark witness as done'}</Button>
            </div>
          }
          {addNoteState &&
            <div className='bg-blue-500'>
              <p className='text-white'>add note for {witness.witnessFirstName} {witness.witnessLastName} </p>
              <RTEComponent readonly={false} setContent={setContent}/>
              {/* <RTEComponent text='Type your note here...' readonly={false} setContent={setContent} /> */}
              <Button className='bg-green-100 hover:bg-green-300' type='reset' click={() => saveNoteHander(witness._id, content, index)} >save Note</Button>
              <Button className='bg-red-100 hover:bg-red-300' click={cancelAddNote}>Cancel</Button>
            </div>
          }

        </div>
      }
    </div>
  )
}
