import axios from 'axios'
import React, { useState } from 'react'
import Env from '../../.env.mjs'
import Button from '../elements/button'

export default function Admin () {
  const [results, setResults] = useState({})
  const ping = () => {
    console.log('ping')
    axios.post(`${Env.API_URL}/admin`, { str: 'ping' }, { withCredentials: true })
      .then(res => setResults(res))
  }
  const noatty = () => {
    axios.get(`${Env.API_URL}/noatty`)
      .then(res => {
        console.log(res)
        setResults(res)
      })
      .catch(err => {
        console.log(err)
        setResults(err)
      })
  }
  return (
    <div>
      <p>

        Admin Page

      </p>

      <Button click={ping}> ping me</Button>
      <Button click={noatty}>cases with no atty</Button>
      {results.data && <pre>{JSON.stringify(results.data, null, 2)}</pre>}
    </div>
  )
}
