import React from 'react'
export default function Button ({ className = '', children, disabled, click, title, type }) {
  return (
    // drop-shadow-4xl
    <button type={type} title={title} disabled={disabled} className={`
    active:drop-shadow-3xl
    select-none
    font-mono
    font-bold
    m-1.5
    p-1.5
    active:translate-y-[2px]
    border-2
    border-gray-400
    rounded-md
    ${className}`}
    onClick={click}>
      {children}
    </button>
  )
}
