/* eslint-disable no-unused-vars */
import axios from 'axios'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import Env from '../.env.mjs'
import { appContext } from '../App'
import RTEComponent from './RTEComponent'
import Button from './elements/button'
import Input from './elements/input'
// import RCWSearchBar from './rcwSearchBar'

export default function CreateTodo () {
  const navigate = useNavigate()
  const [content, setContent] = useState('')
  const { serializeForm, user } = useContext(appContext)
  // console.log(user)
  const [users, setUsers] = useState([])
  useEffect(() => {
    window.document.title = 'create'
    axios.get(`${Env.API_URL}/users`, { withCredentials: true })
      .then(res => {
        // console.info(res.data)
        setUsers(res.data)
      })
      .catch(err => console.info(err))
  }, [])
  const [redirect, setRedirect] = useState({
    create: false,
    cancel: false,
    location: ''
  })
  const form = useRef()
  const notesContent = useRef()

  function checkProperties (obj) {
    for (const key in obj) {
      // console.info(obj[key])
      if (obj[key] === '' || obj[key] === null) {
        return false
      }
    }
    return true
  }

  const createTask = () => {
    const body = serializeForm(form)
    const dateArr = body.nextCourtDate.split('-')
    /* mozz doesnt handle this date formate for some reason */
    // const myDate = [dateArr[1], dateArr[2], dateArr[0]]
    const myDate = `${dateArr[1]}-${dateArr[2]}-${dateArr[0]}`
    const localDate = new Date(myDate).getTime()
    body.nextCourtDate = localDate
    body.notes = JSON.stringify(content)
    body.organization = user?.organization

    console.log({ body, localDate, dateArr, myDate })
    console.info(body)
    if (checkProperties(body) && !isNaN(localDate)) {
      // console.log('create')
      fetch(`${Env.API_URL}/tasks`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
        .then((res) => {
          // console.log(res)
          return res.json()
        })
        .then((data) => {
          // console.log(data)
          setRedirect({ ...redirect, create: true, location: data._id })
        })
        .catch((err) => {
          console.info('errrrr createtodo.js')
          console.info('Err:', err)
        })
    }
  }
  const cancelCreate = () => {
    setRedirect({ ...redirect, cancel: true })
  }
  if (redirect.cancel === true) {
    return <Navigate to="/" />
  }
  if (redirect.create === true) {
    return <Navigate to={`/tasks/_id/${redirect.location}`} />
    // return navigate(`/tasks/_id/${redirect.location}`)
  } else {
    return (
      <div className='select-none'>
        <p className="text-xl mx-auto border-black border-b-2 col-span-3 my-3.5 font-bold px-12 w-11/12 text-center">
          create a new case (all fields required)
        </p>
        <form
          className="p-5 grid grid-cols-12"
          ref={form}
          action=""
          id="witness">
          <div className="col-span-4  ">
            <label className='text-center flex flex-col'>
              First Name
              <Input name="firstName" placeholder="firstName" />
            </label>
          </div>
          <div className="col-span-4">
            <label className='text-center flex flex-col'>
              Last Name
              <Input name="lastName" placeholder="lastName" />
            </label>
          </div>
          <div className="col-span-4">
            <label className='text-center flex flex-col'>
              Charge
              <Input name="charge" placeholder="charge" />
            </label>
          </div>
          <div className="col-span-3">
            <label className='text-center flex flex-col'>
              Case Number
              <Input name="caseNumber" placeholder="caseNumber" />
            </label>
          </div>
          <div className="col-span-2">
            <label className='text-center flex flex-col' >
              Next Court Date
              <Input name="nextCourtDate"
                className=" "
                type="date" />
            </label>
          </div>

          <div className="col-span-3 ">
            <label className='text-center flex flex-col ' >
              Attorney
              <select className='capitalize appearance-none m-2 p-2 rounded-lg border-gray-300 bg-gray-100 focus:bg-white focus:outline-none focus:ring-2 focus:border-transparent border-2' name="attorney">
                <option className='p-2 underline' value={user?.fullName} hidden>{user?.fullName}</option>
                {users.map(user => {
                  return (
                    <option key={user.fullName} value={user.fullName}>{user.fullName}</option>
                  )
                })}
              </select>
            </label>
          </div>
          <div className="col-span-3">
            <label className='text-center flex flex-col' >
              type
              <select className='capitalize appearance-none m-2 p-2 rounded-lg border-gray-300 bg-gray-100 focus:bg-white focus:outline-none focus:ring-2 focus:border-transparent border-2' name="type">
                <option value="misdemeanor">misdemeanor</option>
                <option value="felony">felony</option>
              </select>
            </label>
          </div>
          {/* <textarea name="notes" id="notes" cols="30" rows="10"></textarea> */}
          <div
            className=" italic w-11/12 mt-3 col-span-full justify-self-center"
            ref={notesContent}
            >
              <p>**pease write notes.  witnesses can be added here but after creation you can also add witnesses**</p>
            <RTEComponent readonly={false} setContent={setContent}/>
          </div>
        </form>
        <div className='flex justify-around'>

          <Button className="bg-green-300 hover:bg-green-400" click={createTask}>
            create
          </Button>
          <Button className="bg-red-300 hover:bg-red-400" click={cancelCreate}>
            cancel
          </Button>
        </div>

      </div>
    )
  }
}
