import React from 'react'
import { Route, Routes } from 'react-router-dom'
import CreateTodo from './createTodo'
// import WYSIWYG from './WYSIWYG'
import CaseList from './pages/CaseList'
import Witnesses from './pages/Witnesses'
import Case from './Case/case'
import Admin from './admin'
import NeedToBill from './pages/NeedToBill'
// import RTEComponent from './RTEComponent'
// import TodoListItem from './todoListItem'

export default function Switches () {
  // console.log('switches')
  return (
    <Routes>
      <Route path='/' element={<CaseList/>}></Route>
      <Route path='/needtobill' element={<NeedToBill/>}></Route>
      <Route path='/createTodo' element={<CreateTodo/>}></Route>
      <Route path='/tasks/:field/:value' element={<Case/>}></Route>
      <Route path='/witnesses' element={<Witnesses/>}></Route>
      <Route path='/admin' element={<Admin/>}></Route>
      {/* <Route path='/rte' ><WYSIWYG text='scott is the bomb' readonly={false}/></Route> */}
    </Routes>
  )
}
