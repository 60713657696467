import React, { useEffect, useState } from 'react'
import Env from '../../.env.mjs'
import CaseListItem from '../CaseListItem.js'

export default function NeedToBill () {
  const [todoList, setTodoList] = useState([])

  useEffect(() => {
    fetch(`${Env.API_URL}/tasks/needtobill`,
      {
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' }
      })
      .then(res => res.json())
      .then(data => {
        setTodoList(data)
      })
  }, [])

  return (
    <div>
       {todoList.length >= 1 && todoList.map((todo, index) => {
         return <CaseListItem key={todo._id} todo={todo} index={index} />
       })}
    </div>
  )
}
