import React from 'react'
import { Link } from 'react-router-dom'

export default function SearchResults ({
  showResults,
  searching,
  searchResults,
  searchbarFocus,
  searchQuery

}) {
  const searchingIndicator = (
    <div className="absolute shadow-2xl max-w-6xl max-h-96 whitespace-normal overflow-auto top-8 w-11/12">
      <div className=" bg-blue-100 p-2 border-black border-2">
        searching .....
      </div>
    </div>
  )
  const noResultsIndicator = (
    <div className="animate-pingOut absolute shadow-2xl max-w-6xl max-h-96 whitespace-normal overflow-auto top-8 w-11/12">
      <div className=" bg-blue-100 p-2 border-black border-2">
        no results found
      </div>
    </div>
  )

  function parseAllSearch (obj) {
    /* digest ALL search else just return results */
    if (obj && obj?.type === 'all') {
      const allResults = []
      obj?.res?.forEach((el, i) => {
        if (el.Casefile) {
          el.Casefile.forEach((singleCase) => {
            const {
              firstName,
              _id: caseId,
              lastName,
              caseNumber,
              charge,
              done
            } = singleCase
            const returnVal = {
              firstName,
              caseId,
              lastName,
              caseNumber,
              charge,
              done
            }
            allResults.push(returnVal)
          })
        }
        if (el.Witness) {
          el.Witness.forEach((singleWitness) => {
            const {
              firstName,
              _id: caseId,
              lastName,
              caseNumber,
              charge
            } = singleWitness.caseId
            const { witnessFirstName, witnessLastName } = singleWitness
            const returnVal = {
              firstName,
              caseId,
              lastName,
              caseNumber,
              witnessFirstName,
              witnessLastName,
              charge
            }
            allResults.push(returnVal)
          })
        }
        if (el.Action) {
          el.Action.forEach((singleAction, i) => {
            const {
              firstName,
              _id: caseId,
              lastName,
              caseNumber,
              charge
            } = singleAction.caseId

            const returnVal = {
              firstName,
              caseId,
              lastName,
              caseNumber,
              charge
            }
            allResults.push(returnVal)
          })
        }
      })
      return allResults
    }
    return obj?.res
  }
  const consolodate = (obj) => {
    const consolodatedArr = []
    obj?.forEach((objItem) => {
      if (objItem.caseId === undefined) {
        return consolodatedArr.push(objItem)
      }
      const exists = consolodatedArr.filter((v) => {
        return v.caseId === objItem.caseId
      })
      if (exists.length) {
        const existingIndex = consolodatedArr.indexOf(exists[0])
        consolodatedArr[existingIndex] = {
          ...consolodatedArr[existingIndex],
          ...objItem
        }
        return consolodatedArr
      } else {
        return consolodatedArr.push(objItem)
      }
    })
    return consolodatedArr
  }
  const consolidatedResults = consolodate(parseAllSearch(searchResults))
  const results = (
    <div className="absolute shadow-2xl max-w-6xl max-h-96 whitespace-normal overflow-auto top-8 w-11/12 animate-pingIn">
      {consolidatedResults?.length >= 1 &&
        consolidatedResults.map((result, i) => {
          const linkId = result.caseId?._id || result.caseId || result._id
          return (
            <Link key={linkId} to={`/tasks/_id/${linkId}`}>
              <div

                className="animate-pingIn text-base border-black shadow-2xl p-2 border-2 bg-blue-200 hover:bg-gray-200"
              >
                <p className="text-center font-bold text-xl mx-14 ">
                  client: {result.caseId?.firstName || result.firstName}{' '}
                  {result.caseId?.lastName || result.lastName}{' '}
                </p>
                <div className="flex justify-around">
                  <p>
                    <span className="font-bold">case#</span>:{' '}
                    {result.caseId?.caseNumber || result.caseNumber}
                  </p>
                  <p> {result.charge}</p>
                </div>
                {result.witnessFirstName && (
                  <p className="text-center">
                    <span className="font-bold">witness</span> :{' '}
                    {`${result.witnessFirstName} ${result.witnessLastName}`}
                  </p>
                )}
                {result.done && <p className="bg-red-300">done</p>}
              </div>
            </Link>
          )
        })}
    </div>
  )

  return (
    <>
      {searching && searchingIndicator}
      {showResults && results}
      {!searching && searchQuery.value !== '' && showResults && consolidatedResults?.length === 0 && noResultsIndicator}
    </>
  )
}
